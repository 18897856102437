<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmAdminFaqFormData && Object.keys(vmAdminFaqFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationfaq_module">
                    {{cvFaqModuleLabel}}</label
                  >
                  <input
                    v-model="vmAdminFaqFormData.faq_module"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationfaq_title">
                  {{cvFaqTitleLabel}}</label
                >
                <vue-editor v-model="vmAdminFaqFormData.faq_title" required></vue-editor>
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationfaq_description">
                {{cvFaqDescriptionLabel}}</label
              >
              <vue-editor v-model="vmAdminFaqFormData.faq_description" required></vue-editor>
          </div>
          </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="AdminFaqEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { AdminFaq } from "../../../FackApi/api/AdminFaq"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "AdminFaqEdit",
  components: {
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propAdminFaqObj: {
      type: Object,
      default: function () {
        return {
          "faq_module": "",
          "faq_title": "",
          "faq_description": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "admin_faq_edit",
      cvCardTitle: "Edit Admin Faq",
      cvCardSubHeader: "Edit Admin Faq sub header",
      cvSubmitBtn: "Edit",
      cvFaqModuleLabel: "Faq Module",
      cvFaqTitleLabel: "Faq Title",
      cvFaqDescriptionLabel: "Faq Description",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admin Faq Updated",
      vmAdminFaqFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.AdminFaqView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmAdminFaqFormData) {
          if (!this.vmAdminFaqFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * AdminFaqView
     */
    async AdminFaqView () {
      try {
        if (this.propOpenedInModal) {
          this.vmAdminFaqFormData = this.propAdminFaqObj
        }
        else {
          let AdminFaqId = this.$route.params.faq_id
          let adminFaqViewResp = await AdminFaq.AdminFaqView(this, AdminFaqId)
          if (adminFaqViewResp && adminFaqViewResp.resp_status) {
            this.vmAdminFaqFormData = adminFaqViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqView() and Exception:", err.message)
      }
    },
    /**
     * AdminFaqEdit
     */
    async AdminFaqEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let AdminFaqAddResp = await AdminFaq.AdminFaqEdit(this, this.vmAdminFaqFormData)
        await ApiResponse.responseMessageDisplay(this, AdminFaqAddResp)

        if (AdminFaqAddResp && !AdminFaqAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseAdminFaqEditModal", this.vmAdminFaqFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
